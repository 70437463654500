










































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import {
    // sendActivationEmail,
    login,
    recoverPassword,
    send2FaCode,
} from '@/api/userapi/users'
import { get } from 'lodash'
import VsLoader from '@/components/VsLoader/Index.vue'
import {
    eraseCookie,
    getCookie,
    setCookie,
} from '@/utils/cookies'
import {
    isInWhitelist,
    getParameterByName,
} from '@/utils/queryString'
import axios from 'axios'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'Login',
    components: {
        VsLoader,
    },
})
export default class extends Vue {
    private apicalling = false
    private accountBlockedMessage = ''
    private twoFaCode = ''
    private twoFaToken = ''
    private loginStep = 'EMAIL'
    private inputType = 'password'
    private redirectUrl: any = ''
    private loginData = {
        email: '',
        password: '',
        utmCampaign: '',
        utmMedium: '',
        utmSource: '',
        referrer: window.location.href,
    }

    $refs: any

    get siteUrl () {
        return AppModule.siteUrl
    }

    get redirectCookie () {
        return AppModule.redirectCookie
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    private setRedirectUrl () {
        const redirectUrl = get(this.$route, 'query.redirect', getCookie(this.redirectCookie))
        if (isInWhitelist(redirectUrl)) {
            setCookie(this.redirectCookie, redirectUrl, 365)
            this.redirectUrl = redirectUrl
        }
    }

    mounted () {
        this.setFocus()
        this.setRedirectUrl()
        this.getUtmParams()
    }

    private showPwd (e: any) {
        e.preventDefault()
        this.inputType = this.inputType === 'password' ? 'text' : 'password'
    }

    private getUtmParams () {
        const utm_campaign = getParameterByName('utm_campaign', window.location.href)
        const utm_medium = getParameterByName('utm_medium', window.location.href)
        const utm_source = getParameterByName('utm_source', window.location.href)
        if (utm_campaign) {
            this.loginData.utmCampaign = utm_campaign
        }
        if (utm_medium) {
            this.loginData.utmMedium = utm_medium
        }
        if (utm_source) {
            this.loginData.utmSource = utm_source
        }
    }

    private backToFirstStep () {
        this.loginData.password = ''
        this.loginData.email = ''
        this.twoFaCode = ''
        // this.errors.clear()
        this.loginStep = 'EMAIL'
    }

    private openContact () {
        if (window.Beacon) {
            window.Beacon('open')
        } else {
            window.open(this.siteUrl + '/contatta-4dem/', '_blank')
        }
    }

    private setFocus () {
        if (typeof this.$refs.autofocus !== 'undefined') {
            this.$refs.autofocus.$children[0].$refs.input.focus()
        }
    }

    private async forgottenPassword () {
        this.apicalling = true
        try {
            await recoverPassword(this.loginData.email)
        } catch (e) {
            console.log(e)
        }
        this.loginStep = 'CHECK_EMAIL_PASSWORD'
        this.apicalling = false
    }

    private async login () {
        if (this.redirectUrl) {
            eraseCookie(this.redirectCookie)
            window.location.href = this.redirectUrl
        } else {
            this.$router.push({
                name: 'dashboardIndex',
            })
        }
    }

    private async submitPassword () {
        this.apicalling = true
        try {
            const response = await login(this.loginData)

            const twoFaEnabled = get(response.data, 'is2FaEnabled', false)
            if (twoFaEnabled) {
                this.twoFaToken = get(response.data, 'token', '')
                this.loginStep = 'CHECK_2FA_CODE'
                this.apicalling = false
                return
            }
            await this.login()
        } catch (e) {
            if (axios.isAxiosError(e)) {
                // const message = get(e, 'response.data.message', [])
                const status = get(e, 'response.status', '')
                if (status === 401) {
                    this.$refs.loginStepEmail.setErrors({ password: ['Login errato, verifica i tuoi dati e riprova'] })
                }
                if (status === 429) {
                    this.$root.$vsToast({
                        heading: 'Hai fatto troppi tentativi di login, riprova più tardi.',
                        timeout: 3000,
                        aspect: VsToastAspectEnum.alert,
                    })
                }
            }
        }
        this.apicalling = false
    }

    private async checkTwoFaCode () {
        if (!this.twoFaCode) return false
        this.apicalling = true
        try {
            await send2FaCode({
                ...this.loginData,
                code: this.twoFaCode,
            }, this.twoFaToken)
            await this.login()
        } catch (e) {
            console.log(e)
            this.$refs.loginStep2Fa.setErrors({
                twoFaCode: ['Il codice potrebbe essere scaduto o errato'],
            })
        }
        this.apicalling = false
    }
}
